import React from 'react';
import IconPageComponent from 'components/icon-page';
import 'styles/project.scss';

export default function IconsTemplate(props) {
  return (
    <>
      <main>
        <IconPageComponent icons={props.pageContext.icons} />
      </main>
    </>
  );
}
