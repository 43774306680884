import React, { useEffect, useState } from 'react';
import Container from 'container';
import Icon from 'icon';
import ClipboardJS from 'clipboard';
import s from './icons.module.scss';

export default function IconPageComponent({ icons }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (window) window.clippy = new ClipboardJS('.btn-card');
  }, []);

  const handleClick = () => {
    setShow(true);
    setTimeout(() => (setShow(false)), 1500);
  };
  return (
    <>
      <Container className={s.section}>
        <div className={s.grid}>
          {icons.map((icon, i) => (
            <div onClick={() => handleClick()} className={`btn-card ${s.card}`} data-clipboard-target={`#icon-${i}`}>
              <div className={s.iconWrapper}>
                <Icon icon={icon} />
              </div>
              <div className={s.iconName}>
                <p id={`icon-${i}`}>{icon}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>
      <div className={`${s.toast} ${show ? s.show : ''}`}>
        <Icon icon="checkCircle" />
        <p>Coppied to clipboard!</p>
      </div>
    </>
  );
}
